<template>
    <div :style="{ width: width + 'px' }">
        <div :id="editorId" class="editor"></div>
        <template v-if="is_site">
            <UploadSource v-if="showImgLibrary" :isMany="true" @changeStatus="changeStatus" :dialogVisible="showImgLibrary"
                @getSource="getImg"></UploadSource>
        </template>
        <template v-else>
            <ImgLibrary v-if="showImgLibrary" :isMany="true" :url="1" type="image" @close="close"
                @changeStatus="changeStatus" :dialogVisible="showImgLibrary" @getImg="getImg"></ImgLibrary>
        </template>
        <UploadSource v-if="showVideoLibrary" :isMany="true" @changeStatus="changeStatusVideo"
            :dialogVisible="showVideoLibrary" @getSource="getVideo" type="video"></UploadSource>
    </div>
</template>

<script>
import UploadSource from './uploadSource';
import ImgLibrary from '@/components/imgLibrary';
import E from 'wangeditor';
let _this = null;
const { BtnMenu } = E;
class imgLibraryMenu extends BtnMenu {
    constructor(editor) {
        // data-title属性表示当鼠标悬停在该按钮上时提示该按钮的功能简述
        const $elem = E.$(
            `<div class="w-e-menu" data-title="上传图片">
            <i style="font-size:17px;" class="el-icon-picture"><i>
            </div>`
        );
        super($elem, editor);
    }
    // 菜单点击事件
    clickHandler () {
        _this.showImgLibrary = !0;
    }
    tryChangeActive () { }
}
class videoLibraryMenu extends BtnMenu {
    constructor(editor) {
        // data-title属性表示当鼠标悬停在该按钮上时提示该按钮的功能简述
        const $elem = E.$(
            `<div class="w-e-menu" data-title="上传视频">
            <i style="font-size:17px;" class="el-icon-video-camera"><i>
            </div>`
        );
        super($elem, editor);
    }
    // 菜单点击事件
    clickHandler () {
        _this.showVideoLibrary = !0;
    }
    tryChangeActive () { }
}
export default {
    props: {
        width: {
            type: Number,
            default: 900,
        },
        height: {
            type: Number,
            default: 600,
        },
        richTxt: {
            type: String,
            default: '',
        },
        is_site: {
            type: Number,
            default: 1, // 1、在站点内调用富文本 0、在站点外调用富文本
        },
        editorId: {
            type: String,
            default: 'editor',
        },
    },
    data () {
        return {
            showImgLibrary: !1,
            showVideoLibrary: !1,
            editor: null,
            is_editor: true,
        };
    },
    watch: {
        richTxt: {
            handler (val) {
                if (this.editor && this.is_editor) {
                    this.is_editor = false;
                    this.editor.txt.html(val);
                }
            },
            immediate: true,
        },
    },
    components: {
        UploadSource,
        ImgLibrary,
    },
    mounted () {
        _this = this;
        const menuKey = 'imgLibraryMenuKey';
        const videoKey = 'videoLibraryMenuKey';
        const onName = _this.editorId == 'editor' ? 'soninfo' : _this.editorId;
        this.editor = new E('#' + this.editorId);
        // 注册菜单
        this.editor.menus.extend(menuKey, imgLibraryMenu);
        this.editor.menus.extend(videoKey, videoLibraryMenu);
        this.editor.config.menus = this.editor.config.menus.concat(menuKey);
        this.editor.config.menus = [
            'head',
            'bold',
            'fontSize',
            'fontName',
            'italic',
            'underline',
            'strikeThrough',
            'indent',
            'lineHeight',
            'foreColor',
            'backColor',
            'link',
            'list',
            'todo',
            'justify',
            'quote',
            'imgLibraryMenuKey',
            'videoLibraryMenuKey',
            // 'image',
            // 'video',
            'table',
            'code',
            'splitLine',
            'undo',
            'redo',
        ];
        this.editor.config.customUploadImg = function (resultFiles, insertImgFn) {
            console.log(resultFiles);
            resultFiles.map(async item => {
                let url = await _this.uploadImg(item, 'files');
                insertImgFn(url);
            });
        };
        this.editor.config.customUploadVideo = function (resultFiles, insertVideoFn) {
            resultFiles.map(async item => {
                let url = await _this.uploadImg(item, 'files');
                insertVideoFn(url);
            });
        };
        this.editor.config.onchange = function (newHtml) {
            if (onName == 'soninfo') {
                _this.$emit(onName, newHtml);
            } else {
                //处理电竞添加活动的富文本
                _this.$emit('getContent', newHtml, onName);
            }
        };
        this.editor.config.menuTooltipPosition = 'down';
        this.editor.config.height = this.height;
        this.editor.create();
        this.editor.txt.html(this.richTxt);
    },
    methods: {
        changeStatus (val) {
            this.showImgLibrary = val;
        },
        close () {
            this.showImgLibrary = !1;
        },
        changeStatusVideo (val) {
            this.showVideoLibrary = val;
        },
        getImg (val) {
            for (let i in val) {
                this.editor.cmd.do('insertHTML', `<img src="${val[i].path}" />`);
            }
        },
        getVideo (val) {
            for (let i in val) {
                this.editor.cmd.do('insertHTML', `<video src="${val[i].path}" autoplay loop></vidoe>`);
            }
        },
        uploadImg (file, type) {
            return new Promise(resolve => {
                let formData = new FormData();
                formData.append(type, file);
                this.$axios.uploadFile(this.$api.user.addFiles, formData).then(res => {
                    if (res.code == 0) {
                        resolve(res.result.path);
                    } else {
                        this.$message.error(res.msg);
                    }
                });
            });
        },
    },
};
</script>

<style lang="less">
.editor {
    .w-e-toolbar {
        z-index: 10 !important;
    }

    .w-e-text-container {
        z-index: 9 !important;
    }
}
</style>
